// Add here all polyfills needed
import * as keys from 'lodash.keys';
import 'isomorphic-unfetch';

/**
 * !!! README first !!!
 *
 * Instead of installing pollyfills penalizing modern browsers,
 * we are using a service from https://polyfill.io/
 *
 * They have most of the polyfills we need (check their feature list
 * here: https://polyfill.io/v2/docs/features/)
 *
 * There are some good examples here: https://polyfill.io/v2/docs/examples
 */

import 'intersection-observer';

/** IE polyfills */

/* installing package: yarn add core-js */
// import 'core-js';

/** end IE polyfills */

/** Custom polyfills */
import OpusMediaRecorder from 'opus-media-recorder';

// Polyfill MediaRecorder
window.MediaRecorder = OpusMediaRecorder;

Object.keys = keys;
